@layer components {
	.shoppingcart-product__min, .shoppingcart-product__plus {
		@apply w-[38px] h-[38px] shrink-0 border border-shoppingcart-input-border bg-shoppingcart-input-bg text-shoppingcart-text-primary rounded cursor-pointer flex flex-row items-center justify-center grow-0;
	}

	.shoppingcart-product__min::part(svg), .shoppingcart-product__plus::part(svg) {
		@apply w-[12px];
	}

	.shoppingcart-product__count {
		@apply w-[80px] h-[40px] shrink-0;
	}

	.shoppingcart-product__count::part(base) {
		@apply bg-transparent border-shoppingcart-input-border;
	}

	.shoppingcart-product__count::part(input) {
		@apply w-[40px] h-[40px] shrink-0 bg-shoppingcart-input-bg text-shoppingcart-text-primary rounded;
	}

	.shoppingcart-product__number {
		@apply flex flex-row flex-wrap gap-1 items-center;
	}

	.shoppingcart-product__number--disabled {
		@apply pointer-events-none opacity-70;
	}
}
