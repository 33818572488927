@layer components {
	.shoppingcart-field::part(form-control-label) {
		@apply font-bold text-xs font-secondary text-shoppingcart-text-secondary mb-2;
	}

	.shoppingcart-field::part(base) {
		@apply text-sm font-secondary text-shoppingcart-text-secondary bg-shoppingcart-input-bg border-shoppingcart-input-border;
	}

	.shoppingcart-field::part(input) {
		@apply px-4 bg-shoppingcart-input-bg;
	}

	.shoppingcart-field::part(display-input), .shoppingcart-field::part(combobox) {
		@apply bg-shoppingcart-input-bg text-sm border-shoppingcart-input-border;
	}

	.shoppingcart-field::part(textarea) {
		@apply h-[144px];
	}

	.shoppingcart-field::part(combobox) {
		@apply h-[50px];
	}

	.shoppingcart-field::part(form-control-label)::after {
		@apply text-alert-danger;
	}

	.shoppingcart-field::part(form-control-help-text) {
		@apply text-xs font-secondary text-shoppingcart-text-secondary mt-2;
	}
}
