@layer base {
	:root.sc-light {
        --shoppingcart-primary: 134 235 109;
		--shoppingcart-secondary: 0 0 0;
        --shoppingcart-background: 255 255 255;
        --shoppingcart-grey-tone-one: 248 248 248;
		--shoppingcart-grey-tone-two: 218 218 218;
		--shoppingcart-grey-tone-three: 153 153 153;
		--shoppingcart-text-primary: 39 37 37;
		--shoppingcart-text-secondary: 153 153 153;
        --shoppingcart-input-bg: 255 255 255;
        --shoppingcart-input-border: 218 218 218;
	}

    :root.sc-dark {
        --shoppingcart-primary: 134 235 109;
		--shoppingcart-secondary: 134 235 109;
        --shoppingcart-background: 18 18 18;
        --shoppingcart-grey-tone-one: 0 0 0;
		--shoppingcart-grey-tone-two: 218 218 218;
		--shoppingcart-grey-tone-three: 62 62 62;
		--shoppingcart-text-primary: 255 255 255;
		--shoppingcart-text-secondary: 153 153 153;
        --shoppingcart-input-bg: 0 0 0;
        --shoppingcart-input-border: 62 62 62;
	}
}

