@layer components {
	.shoppingcart-button {
		@apply flex px-2 gap-2 items-center justify-center text-sm font-bold font-primary min-h-[50px] max-w-full w-full cursor-pointer transition-colors;
	}

	.shoppingcart-button--primary {
		@apply bg-shoppingcart-primary text-black border border-transparent hover:underline;
	}

	.shoppingcart-button--secondary {
		@apply bg-transparent border border-shoppingcart-secondary hover:bg-transparent hover:border-transparent text-shoppingcart-text-primary;
	}

	.shoppingcart-button--secondary span {
		@apply underline;
	}

	.shoppingcart-button:disabled {
		@apply cursor-not-allowed bg-shoppingcart-grey-tone-one border-shoppingcart-grey-tone-one text-shoppingcart-text-primary;
	}

	i {
		@apply text-base;
	}
}
