@import 'magnific-popup/dist/magnific-popup.css';
@import 'vanilla-cookieconsent/dist/cookieconsent.css';
@import 'animate.css/animate.css';
@import 'nouislider/dist/nouislider.css';

@import '@shoelace-style/shoelace/dist/themes/light.css';

@import './preview.css';
@import '../pagewize/_shoppingcart/src/index.css';

.iti {
	--iti-path-flags-1x: url('/_frontend/img/flags.webp');
	--iti-path-flags-2x: url('/_frontend/img/flags@2x.webp');
	--iti-path-globe-1x: url('/_frontend/img/globe.webp');
	--iti-path-globe-2x: url('/_frontend/img/globe@2x.webp');
}
