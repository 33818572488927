@layer base {
	:root.pv-light {
        --preview-primary: 134 235 109;
        --preview-background: 255 255 255;
        --preview-grey-tone-one: 248 248 248;
		--preview-text-primary: 255 255 255;
		--preview-text-secondary: 39 37 37;
		--preview-text-default: 39 37 37;
	}

    :root.pv-dark {
        --preview-primary: 134 235 109;
        --preview-background: 18 18 18;
        --preview-grey-tone-one: 62 62 62;
		--preview-text-primary: 255 255 255;
		--preview-text-secondary: 255 255 255;
        --preview-text-default: 255 255 255;
	}
}